import React from 'react';
import { Container, Header, Segment, Grid, GridColumn, GridRow, Image } from 'semantic-ui-react';
import screenshotA from '../images/slowmo/in-game-normal-1-6.5inch.png'
import screenshotB from '../images/slowmo/in-game-normal-2-6.5inch.png'
import screenshotC from '../images/slowmo/in-game-normal-3-6.5inch.png'
import screenshotD from '../images/slowmo/in-game-normal-4-6.5inch.png'
import appstoreBadge from '../images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import playstoreBadge from '../images/google-play-badge.png'

const SlowMoClimb = () => {
    return (
            <Container text
                style={{
                    marginTop: '1em'
                }}
            >
                <Header 
                    as='h1'
                    style={{
                        fontSize: '4em',
                        marginTop: '1em'
                    }}
                >
                    Slow-mo Climb
                </Header>
                <Header 
                    as='h2'
                    style={{
                        fontSize: '1.5em',
                        marginTop: '0.5em'
                    }}
                >
                    Warp time to dodge and climb!
                </Header>
                <Grid columns={3} centered>
                    <GridRow>
                        <GridColumn style={{padding: '0px'}}>
                            <Image as='a' style={{margin: '6%',width: '88%'}} target='_blank' href='https://apps.apple.com/us/app/id1521152441' src={appstoreBadge} />
                        </GridColumn>
                        <GridColumn style={{padding: '0px'}}>
                            <Image as='a' style={{paddingTop: '1%'}} target='_blank' alt='Get it on Google Play' href='https://play.google.com/store/apps/details?id=com.owennash.slowmoclimb&utm_source=website&utm_campaign=website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' src={playstoreBadge} />
                        </GridColumn>
                    </GridRow>
                </Grid>
                <br/>
                <p>
                    Slow down time to dodge obstacles and climb as high as you can before the rising lava catches you!
                    Three difficulty settings, simple controls, personal high-scores and dozens of obstacles. Face a uniquely generated level every time you play!
                </p>
                <br/>
                <Segment>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <Image src={screenshotA} />
                            </Grid.Column>
                            <Grid.Column>
                                <Image src={screenshotB} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Image src={screenshotC} />
                            </Grid.Column>
                            <Grid.Column>
                                <Image src={screenshotD} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Container>
    );
};

export default SlowMoClimb;