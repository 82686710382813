import React from 'react';
import { Container, Header, Segment, Grid, GridRow, GridColumn, Image } from 'semantic-ui-react';
import screenshotA from '../images/slabslider/Editor Screenshot 3.png'
import screenshotB from '../images/slabslider/Editor Screenshot 6.png'
import screenshotC from '../images/slabslider/Editor Screenshot 9.png'
import screenshotD from '../images/slabslider/Editor Screenshot 12.png'
import previewGif from '../images/slabslider/preview-6.5inch_no_mouse.gif'
import appstoreBadge from '../images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import playstoreBadge from '../images/google-play-badge.png'

const SlabSlider = () => {
    return (
            <Container text
                style={{
                    marginTop: '1em'
                }}
            >
                <Header 
                    as='h1'
                    style={{
                        fontSize: '4em',
                        marginTop: '1em'
                    }}
                >
                    Slow-mo Climb
                </Header>
                <Header 
                    as='h2'
                    style={{
                        fontSize: '1.5em',
                        marginTop: '0.5em'
                    }}
                >
                    Colour Merging Sliding Puzzle!
                </Header>
                <Grid columns={3} centered>
                    <GridRow>
                        <GridColumn style={{padding: '0px'}}>
                            <Image as='a' style={{margin: '6%',width: '88%'}} target='_blank' href='https://apps.apple.com/us/app/id1528898373' src={appstoreBadge} />
                        </GridColumn>
                        <GridColumn style={{padding: '0px'}}>
                            <Image as='a' style={{paddingTop: '1%'}} target='_blank' alt='Get it on Google Play' href='https://play.google.com/store/apps/details?id=com.owennash.slabslider&utm_source=website&utm_campaign=website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' src={playstoreBadge} />
                        </GridColumn>
                    </GridRow>
                </Grid>
                <br/>
                <p>
                    A simple but challenging sliding puzzle game with HUNDREDS of levels!
                </p>
                <p>
                    Swipe to slide colored slabs around the board and get them to their matching targets.
                    Primary color slabs (red, blue and yellow) will merge together to make secondary color slabs (purple, orange and green). Clear the board to complete each level!
                </p>
                <p>
                    For an extra challenge complete the levels in as few moves as possible to collect maximum stars - and track your progress as you go!
                </p>
                <br/>
                <Segment>
                    <Grid columns={3}>
                        <Grid.Row>
                            <Grid.Column>
                                <Image src={previewGif} />
                            </Grid.Column>
                            <Grid.Column>
                                <Image src={screenshotA} />
                            </Grid.Column>
                            <Grid.Column>
                                <Image src={screenshotB} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Image src={screenshotC} />
                            </Grid.Column>
                            <Grid.Column>
                                <Image src={screenshotD} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Container>
    );
};

export default SlabSlider;