import React from 'react';
import { Container, Header, Icon } from 'semantic-ui-react';

const Contact = () => {
    return (
            <Container text>
                <Header 
                    as='h1'
                    style={{
                        fontSize: '2em',
                        marginTop: '1em'
                    }}
                >
                    Any Questions?
                </Header>
                <Header 
                    as='h3'
                    style={{
                        fontSize: '1em',
                        marginBottom: '1em'
                    }}
                >
                    Drop me a line at owen@owennash.com or find me on <a target='_blank' rel='noopener noreferrer' href='https://twitter.com/OwenNashGames'>Twitter <Icon name='twitter' /></a>
                </Header>
            </Container>
    );
};

export default Contact;