import React from 'react';
import { Container, Card, Segment, Image, Button, Icon, Grid, GridRow, GridColumn } from 'semantic-ui-react';
import slomMoIcon from '../images/slowmo/icon.png'
import slabSliderIcon from '../images/slabslider/Icon1024.png'
import appstoreBadge from '../images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import playstoreBadge from '../images/google-play-badge.png'

const Games = () => {
    return (
            <Container text
                style={{
                    marginTop: '1em'
                }}
            >
                <Grid columns={2}>
                    <GridRow>
                        <GridColumn>
                            <Card centered fluid>
                                <Image as='a' href='/slabslider' src={slabSliderIcon} />
                                <Card.Content>
                                    <Card.Header>Slab Slider</Card.Header>
                                    <Card.Description>Color Merging Sliding Puzzle!</Card.Description>
                                    <Segment vertical>
                                        <Grid columns={2}>
                                            <GridRow>
                                                <GridColumn style={{padding: '0px'}}>
                                                    <Image as='a' style={{margin: '6%',width: '88%'}} target='_blank' href='https://apps.apple.com/us/app/id1528898373' src={appstoreBadge} />
                                                </GridColumn>
                                                <GridColumn style={{padding: '0px'}}>
                                                    <Image as='a' style={{paddingTop: '1%'}} target='_blank' alt='Get it on Google Play' href='https://play.google.com/store/apps/details?id=com.owennash.slabslider&utm_source=website&utm_campaign=website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' src={playstoreBadge} />
                                                </GridColumn>
                                            </GridRow>
                                        </Grid>
                                    </Segment>
                                    <Segment vertical>
                                        <Button size='small' as='a' href='/slabslider'>
                                            See More 
                                            <Icon name='right arrow' />
                                        </Button>
                                    </Segment>
                                </Card.Content>
                            </Card>
                        </GridColumn>
                        <GridColumn>
                            <Card centered fluid>
                                <Image as='a' href='/slowmoclimb' src={slomMoIcon} />
                                <Card.Content>
                                    <Card.Header>Slow-mo Climb</Card.Header>
                                    <Card.Description>Warp time to dodge and climb!</Card.Description>
                                    <Segment vertical>
                                        <Grid columns={2}>
                                            <GridRow>
                                                <GridColumn style={{padding: '0px'}}>
                                                    <Image as='a' style={{margin: '6%',width: '88%'}} target='_blank' href='https://apps.apple.com/us/app/id1521152441' src={appstoreBadge} />
                                                </GridColumn>
                                                <GridColumn style={{padding: '0px'}}>
                                                    <Image as='a' style={{paddingTop: '1%'}} target='_blank' alt='Get it on Google Play' href='https://play.google.com/store/apps/details?id=com.owennash.slowmoclimb&utm_source=website&utm_campaign=website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' src={playstoreBadge} />
                                                </GridColumn>
                                            </GridRow>
                                        </Grid>
                                    </Segment>
                                    <Segment vertical>
                                        <Button size='small' as='a' href='/slowmoclimb'>
                                            See More 
                                            <Icon name='right arrow' />
                                        </Button>
                                    </Segment>
                                </Card.Content>
                            </Card>
                        </GridColumn>
                    </GridRow>
                </Grid>
            </Container>
    );
};

export default Games;