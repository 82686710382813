import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import SlowMoClimbPrivacy from './pages/privacy/SlowMoClimbPrivacy';
import SlowMoClimb from './pages/SlowMoClimb';
import Home from './pages/Home';
import { Container, Segment, Menu } from 'semantic-ui-react';
import Games from './pages/Games';
import Contact from './pages/Contact';
import SlabSliderPrivacy from './pages/privacy/SlabSliderPrivacy';
import SlabSliderTerms from './pages/privacy/SlabSliderTerms';
import SlabSlider from './pages/SlabSlider';

function App() {
  return (
    <Router>
      <Segment
        vertical
        textAlign='center'
        inverted
      >
        <Menu
          size='large'
          fixed='top'
          inverted
        >
          <Container>
            <Menu.Item as='a' href='/' active>
              Home
            </Menu.Item>
            <Menu.Item as='a' href='/games'>
              Games
            </Menu.Item>
            <Menu.Item as='a' href='/contact'>
              Contact
            </Menu.Item>
          </Container>
        </Menu>
      </Segment>
      <Segment
        vertical
        textAlign='center'
      >
        <Switch>
          <Route path="/slowmoclimb-privacy">
            <SlowMoClimbPrivacy />
          </Route>
          <Route path="/slabslider-privacy">
            <SlabSliderPrivacy />
          </Route>
          <Route path="/slabslider-terms">
            <SlabSliderTerms />
          </Route>
          <Route path="/slabslider">
            <SlabSlider />
          </Route>
          <Route path="/slowmoclimb">
            <SlowMoClimb />
          </Route>
          <Route path="/games">
            <Games />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Segment>

      {/* <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/slowmoclimb-privacy">Privacy</Link>
        </li>
      </ul> */}



    </Router>
  );
}

export default App;
