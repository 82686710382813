import React from 'react';
import { Container, Header, Button, Icon, Segment, Image, Card } from 'semantic-ui-react';
import icon from '../images/slabslider/Icon1024.png'

const Home = () => {
    return (
            <Container text>
                <Header 
                    as='h1'
                    style={{
                        fontSize: '4em',
                        marginTop: '1em'
                    }}
                >
                    Owen Nash
                </Header>
                <Header 
                    as='h3'
                    style={{
                        fontSize: '2em',
                        marginBottom: '1em'
                    }}
                >
                    Developing small mobile games in my spare time
                </Header>
                <Segment vertical>
                    <p style={{ fontSize: '1.2em' }}>
                        Check out my second game - <strong>Slab Slider!</strong>
                    </p>
                    <Card centered>
                        <Image src={icon} />
                    </Card>
                    <div>
                        <Button primary size='huge' as='a' href='/slabslider'>
                            Go on then, I'll have a look
                            <Icon name='right arrow' />
                        </Button>
                    </div>
                </Segment>
            </Container>
    );
};

export default Home;